<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="page-title-right">
            <router-link
              :to="{
                name: 'hrd-behavior-assessment',
              }"
            >
              <button
                type="button"
                class="btn btn-sm btn-blue waves-effect waves-light mb-2"
              >
                <i class="mdi mdi-arrow-left"></i>
                Back
              </button>
            </router-link>
          </div>
          <h4 class="page-title">View Record</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Employee Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="behaviorAssessment.employee_name"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Employee ID
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="behaviorAssessment.employee_id"
                        disabled
                      />
                    </div>
                  </div>

                  <div class="row mb-2">
                    <label for="short_name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        v-model="behaviorAssessment.program_short_name"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div class="row col-md-5">
                  <div class="row mb-3">
                    <label for="short_name" class="col-md-4 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="behaviorAssessment.program_batch_no"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="date" class="col-md-4 form-label">
                      Joined Date
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="behaviorAssessment.join_date"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label
                      for="evaluating_criteria"
                      class="col-md-4 form-label"
                    >
                      Assessor
                      <span class="text-danger">*</span>
                    </label>
                    <div class="col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        v-model="
                          behaviorAssessment.hrd_behavior_assessments[0]
                            .assessor_employee_name
                        "
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="row g-3 mt-3">
                <div class="col-sm-4">
                  <h4 class="mb-3">Criteria</h4>
                </div>
                <div class="col-sm-4">
                  <h4 class="mb-3">Scores:*</h4>
                </div>
              </div>

              <div
                class="row g-3"
                v-for="(
                  question, index
                ) in behaviorAssessment.hrd_behavior_assessments"
                :key="index"
              >
                <div class="col-sm-4">
                  <p class="mb-3 pt-2 pl-2">{{ question.question_name }}</p>
                </div>
                <div class="col-sm-4">
                  <div class="mt-2">
                    <input
                      type="text"
                      class="form-control"
                      v-model="question.score"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="row g-3">
                <div class="col-sm-4">Total Score</div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="totalScore"
                    disabled
                  />
                </div>
                <div class="col-sm-4">
                  <input
                    type="text"
                    class="form-control mt-2"
                    v-model="hrdScore"
                    disabled
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      behaviorAssessment: [],
      criteria: [],
      totalScore: 0,
      hrdScore: 0,
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },

  methods: {
    async getAssessment() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrl}admin/v2/hrd-behavior-assessment/${this.$route.params.id}/${this.$route.params.assessorId}`
        )
        .then((response) => {
          this.behaviorAssessment = response.data.data;
          this.totalScore =
            this.behaviorAssessment.hrd_behavior_assessments.reduce(
              (total, value) => total + value.score,
              0
            );
            if(this.behaviorAssessment) this.hrdScore = this.behaviorAssessment.hrd_behavior_assessments[0].hrd_score;
          this.loading = false;
          this.$Progress.finish();
        });
    },
  },

  created() {
    this.getAssessment();
  },
};
</script>

<style>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.selectScore {
  padding: 0.45rem 0.9rem;
}
</style>
